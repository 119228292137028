@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
  &::after,
  &::before {
    box-sizing: border-box;
  }
}

html,
body {
  overflow-x: hidden;
}

body {
  position: relative;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  --webkit-font-smoothing: antialiased;
}

button,
input,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

image,
video,
canvas,
svg {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

// Variables
$green-color: #6cff8d;
$dark-green-color: #6cff8c7a;
$red-color: #f44336;
$blue-color: #2196f3;
