.styledButton {
  background-color: transparent;
  position: relative;
  border: none;
  cursor: pointer;
  width: 175px;
  height: 120px;
  background: none;
  color: #fff;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:before {
    content: "";
    background: #fff;
    clip-path: path(
      "M154.5,88.5 C131,113.5 62.5,110 30,89.5 C-2.5,69 -3.5,42 4.5,25.5 C12.5,9 33.5,-6 85,3.5 C136.5,13 178,63.5 154.5,88.5 Z"
    );
    transition: clip-path 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55);
  }

  &:hover:before {
    background-color: lightblue;
    clip-path: path(
      "M143,77 C117,96 74,100.5 45.5,91.5 C17,82.5 -10.5,57 5.5,31.5 C21.5,6 79,-5.5 130.5,4 C182,13.5 169,58 143,77 Z"
    );
  }

  & span {
    mix-blend-mode: difference;
    font-weight: 500;
  }

  &:after {
    content: "";
    height: 85%;
    width: 95%;
    top: 6px;
    border: 1px solid #fff;
    border-radius: 58% 42% 55% 45% / 56% 45% 55% 44%;
    transform: rotate(-20deg);
    transition: transform .5s cubic-bezier(0.585, 2.5, 0.645, 0.55);
  }

  &:hover:after {
    transform: translate3d(0,-5px,0);
  }
  @media (max-width: 800px) {
    & {
      transform: scale3d(0.7,0.7,0.7);
      font-size: 23px;
    }
  }
}
