.landing {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #111;
  color: white;
  overflow: hidden;


  .header {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);


    &__wrap {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 60vw;
    }

    &__left {
      display: flex;
      align-items: center;
      gap: 2rem;

      li {
        font-size: 17px;
        font-weight: 700;
      }
    }

    @media (max-width: 1200px) {
      &__wrap {
        width: 100vw;
      }
    }

    @media (max-width: 700px) {
      &__sm {
        display: none;
      }
    }
    @media (max-width: 370px) {
      & {
        left: 36%;
      }
      &__wrap {
        gap: 20%;
      }
      .styledLink[data-text="Home"] {
        display: none
      } 
    }
    
  }

  .socials {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    z-index: 1;
  }
}