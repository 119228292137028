@import "./base.scss";

.styledLink {
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: currentColor;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s cubic-bezier(0.52, 0.5, 0.3, 0.1);
    transform-origin: right;
  }

  &:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    height: 100%;
    transform: translate3d(150%, 0, 0);
  }

  &:hover {
    cursor: pointer;
  }

  &:hover:before {
    transform: scale3d(1, 1, 1);
    transform-origin: left;
  }

  &:hover:after {
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s cubic-bezier(0.52, 0.5, 0.3, 0.1);
  }

  & span {
    transition: transform 0.3s cubic-bezier(0.52, 0.5, 0.3, 0.1);
  }

  &:hover span {
    transition: transform 0.3s cubic-bezier(0.52, 0.5, 0.3, 0.1);
    transform: translate3d(-150%, 0, 0);
  }
}

.coloredIcon {
  color: #fff;
  font-size: 37px;
  &:hover {
    animation: coloredIconAnimation .2s infinite;
  }
  @keyframes coloredIconAnimation {
    0% {
      text-shadow: 5px 4px $red-color,-5px -6px $blue-color;
    }
    25% {
      text-shadow: -5px -6px $red-color,5px 4px $blue-color;
    }
    50% {
      text-shadow: 5px -4px $red-color,-8px 4px $blue-color;
    }
    75% {
      text-shadow: -8px -4px $red-color,-5px -4px $blue-color;
    }
    100% {
      text-shadow: -5px 0 $red-color,5px -4px $blue-color;
    }
  }
  
}
